import React, {Component} from 'react';
import JourneyRouter from './journeyContainer.route';
import {NavLink} from 'react-router-dom';
import Loader from '../../components/loader';
import SectionService from '../../services/getSections';
import CommonService from '../../services/commonService';
import Section from './journeySection';
import SiteSettings from '../../services/siteSettingsService';
import {Helmet} from 'react-helmet';
import smoothscroll from 'smoothscroll-polyfill';
import * as cloneDeep from 'lodash/cloneDeep';
import * as throttle from 'lodash/throttle';
smoothscroll.polyfill();

class Journey extends Component {

    constructor(props) {
        super(props);
        this.state = {
            introSection: null,
            sections: [],
            currentSection: null,
            introSectionChildren: null,
            settings: null,
            responseFormData: {},
            mailResponse: null,
            scrolled: null
        };
        this.SectionService = new SectionService();
        this.CommonService = new CommonService();
        this.currentRef = null;
        this.loadNextSection = this.loadNextSection.bind(this);
        this.loadNextSectionInitial = this.loadNextSectionInitial.bind(this);
        this.goToPrev = this.goToPrev.bind(this);
        this.sectionUpdateId = null
        this.siteSettingsService = new SiteSettings();
    }

    async componentWillMount() {
        var introSection = await this.fetchIntroSection();
        this.setState({
            ...this.state,
            introSection: introSection,
            currentSection: introSection,
        });
        this.getChildrenOfIntroSection();
        await this.getPageSettings();
    }

    async fetchIntroSection() {
        var introSection = await this.SectionService.getIntroSection();
        return introSection;
    }

    async getChildrenOfIntroSection() {
        var children = await this.SectionService.getChildren(this.state.currentSection._id);
        this.setState({
            ...this.state,
            introSectionChildren: [
                ...children,
                {
                    __v: 0,
                    _id: "rPdirE43EfLceSh64Qit99VX",
                    buttonImage: null,
                    buttonText: "Just Browsing",
                    contentType: "link",
                    deleted: false,
                    name: "I Need Support",
                    parentSection: null,
                    question: null,
                    sectionContent: "/corporate-profile"
                }, {
                    __v: 0,
                    _id: "pg2HiNcLG82iRsTewa6QcRCv",
                    buttonImage: null,
                    buttonText: "Your Contact Details",
                    contentType: "link",
                    deleted: false,
                    name: "I Need Support",
                    parentSection: null,
                    question: null,
                    sectionContent: "/contact-us"

                }]

        });
    }

    async getChildrenOfSection() {
        var children = await this.SectionService.getChildren(this.state.currentSection._id);
        this.setState({
            ...this.state,
            introSectionChildren: children
        });
    }

    async loadNextSectionInitial(e) {
        if (this.SectionService) {
            let target = e.target;
            while (target && !target.classList.contains('button')) {
                target = target.parentNode;
            }
            let id = target.id;
            let section = await this.SectionService.getSection(id);
            let newIntroSection = cloneDeep(this.state.introSectionChildren);
            newIntroSection.forEach(child => child._id === id ? child.active = true : child.active = false);

            this.setState({
                ...this.state,
                introSectionChildren: newIntroSection,
                currentSection: section,
                sections: [
                    section
                ]
            });
        }
    }

    async loadNextSection(e, index) {
        let newSections = cloneDeep(this.state.sections);
        if (this.sectionUpdateId >= 1) {
            newSections.splice(index + 1, this.state.sections.length);
        }
        let target = e.target;
        while (target && !target.classList.contains('button')) {
            target = target.parentNode;
        }
        let id = target.id;
        let section = await this.SectionService.getSection(id);

        newSections[index].children.forEach(child => child._id === id ? child.active = true : child.active = false);

        this.setState({
            ...this.state,
            currentSection: section,
            sections: [
                ...newSections,
                section
            ]
        });
    }

    async getPageSettings() {
        var settings = await this.siteSettingsService.getSettings();
		if(settings && settings.length > 0) {
			this.setState({
				...this.state,
				settings: settings[0],
			})
        }
    }

    goToPrev(e, index) {
        if (this.state.sections.length) {
            let isSticky = this.state.sections[index].ref.querySelector('.pl-1').getBoundingClientRect().y > 20;
            if (index < 1) {
                if (isSticky) {
                    document.body.scrollIntoView({ behavior: "smooth" });
                } else {
                    this.state.sections[0].ref.scrollIntoView({ behavior: "smooth" });
                }
            } else {
                this.state.sections[index - (isSticky? 1 : 0)].ref.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            document.body.scrollIntoView({ behavior: "smooth" });
        }
    }

    setRef = (ref) => {
        let { sections } = this.state;
        let buffer = cloneDeep(sections);
        buffer[sections.length - 1].ref = ref;
        this.setState({
            sections: buffer
        })
    };

    componentDidMount() {
        this.watchScroll = throttle(function(){
                let isScrolled = window.scrollY > 50;
                if (!this.state.scrolled === isScrolled) {
                    this.setState({
                        scrolled: true
                    });
                }
            }, 250);
        window.addEventListener('scroll', this.watchScroll.bind(this));
    }

    componentDidUpdate(prevProps, prevState) {
        const prevRef = prevState.sections.length && prevState.sections[prevState.sections.length-1].ref;
        const currRef = this.state.sections.length && this.state.sections[this.state.sections.length-1].ref;
        const length = this.state.sections.length;
        if (prevRef !== currRef && !length < 1 && this.state.sections[length - 1].ref) {
            this.state.sections[length - 1].ref.scrollIntoView({behavior: "smooth"});
        }
    }

    async triggerMail(e) {
        this.setState({
            ...this.state,
            mailResponse: null
        });
        let steps = '';
        let mailResponse;
        let mailRep;
        this.state.sections.map((section, index) => {
            steps = steps.concat('\n'+(index+1) + ' - '+section.section.name+'\n')
        });
        this.setState({
            ...this.state,
            responseFormData: {
                ...this.state.responseFormData,
                journey: steps
            }
        }, mailRep = async () => {
            mailResponse = await this.CommonService.sendMail(this.state.responseFormData);
            this.setState({
                ...this.state,
                responseFormData: {},
                mailResponse: mailResponse
            });
            return mailResponse;
        });
    }

    responseFormDataChange (name, value) {
        var previousData = this.state.responseFormData;
        this.setState({
            ...this.state,
            responseFormData: {
                ...previousData,
                [name]: value
            }
        });
    }

    render() {
        return (
            <div className={this.state.scrolled ? 'main main--scrolled' : 'main main--top'}>
                {this.state.settings ?
                <Helmet>
                    <title>{this.state.settings.seoTitle}</title>
                    <meta name="description" content={this.state.settings.seoDescription} />
                    <meta name="test" content={this.state.settings.seoDescription} />
                </Helmet>
                : '' }
                {this.state.introSection &&
                    <Section
                        index='0.0'
                        sectionType='text'
                        sectionData={this.state.introSection}
                        sectionChildren={this.state.introSectionChildren}
                        question={this.state.introSection.question}
                        buttons={this.state.introSectionChildren}
                        clickHander={this.loadNextSectionInitial}
                        goToPrev={(e) => this.goToPrev(e, 0) }
                    />
                }
                {this.state.sections.length > 0 &&
                    this.state.sections.map((section, index) => {
                        this.sectionUpdateId = index;

                        return (
                            <div key={section.section._id}
                                ref={this.setRef}>
                                <div className="verticalLine"></div>
                                <Section
                                    index='x.x'
                                    sectionType={section.section.contentType}
                                    sectionData={section.section}
                                    sectionChildren={section.children}
                                    question={section.section.question}
                                    buttons={section.children}
                                    clickHander={(e) => this.loadNextSection(e, index)}
                                    goToPrev={(e) => this.goToPrev(e, index) }
                                    triggerMail = {(e) => this.triggerMail(e)}
                                    responseFormDataChange = {(name, value) => this.responseFormDataChange(name, value)}
                                    mailDeliveryResponse = {this.state.mailResponse}
                                />
                            </div>);
                    })
                }
            </div>
        );
    }
}

export default Journey;