import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons'

const propTypes = {
    player: PropTypes.object,
    className: PropTypes.string
};

export default class MuteToggle extends Component {
    constructor(props, context) {
        super(props, context);
        this.setMuted = this.setMuted.bind(this);
    }

    setMuted() {
        return () => {
            this.props.actions.mute(!this.props.player.muted);
        };
    }

    render() {
        const { player, className } = this.props;
        const { currentSrc } = player;

        return (
            <a
                ref={c => {
                    this.button = c;
                }}
                className={classNames(className, {
                    'video-mute-toggle': true
                })}
                tabIndex="0"
                onClick={this.setMuted()}
            >
                <FontAwesomeIcon icon={this.props.player.muted ? faVolumeMute : faVolumeUp} />
            </a>
        );
    }
}

MuteToggle.propTypes = propTypes;
