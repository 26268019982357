import React from 'react';
import { Animated } from 'react-animated-css';

const Loader = props => {
    return (
        <div className="row">
            <div className="section-number">
                <p className="pl-1 ">0.0</p>
            </div>
            <div className="sections-holder">
                <img className="loader" src="../../loader.svg" />

            </div>

        </div>)
}

export default Loader;