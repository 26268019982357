import React, { Component } from 'react';
import TextSection from '../textSection/textSectionComponent';
import ImageGallery from  '../imageGallery/imageGalleryComponent';

class VideoImage extends Component {
    constructor (props) {
        super(props);
    }

    render () {
        return (
            <div className={"image-gallery " + this.props.styleClass}>
                <ImageGallery
                    sectionData={this.props.sectionData}
                    showVideo={true}
                    isRevealed={this.props.isRevealed}
                    parentCallback={this.props.parentCallback}></ImageGallery>
            </div>
        )
    }
}

export default VideoImage;