import React, {Component} from 'react';

import TextSection from '../textSection/textSectionComponent';
import { Player, ControlBar } from 'video-react';
import MuteToggle from './videoMuteComponent';
// import 'intersection-observer'; // optional polyfill
import Observer from '@researchgate/react-intersection-observer';
import { Animated } from 'react-animated-css';

class Video extends Component {
    constructor(props) {
        super(props);
        this.state = {isVisible: false};
        this.handleIntersection = this.handleIntersection.bind(this);
    }

    handleIntersection(event) {
        if (event.isIntersecting) {
            this.player.play();
        } else {
            this.player.pause();
        }
    }

    render() {
        const options = {
            onChange: this.handleIntersection,
            rootMargin: '0% 0% -25%',
        };
        return (
            <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDuration={3000}>
                <div className={"row " + this.props.styleClass}>
                    <div className="col-md-5">
                        <TextSection
                            isRevealed={this.props.isRevealed}
                            parentCallback={this.props.parentCallback}
                            styleClass="white-text"
                            sectionData={this.props.sectionData} />
                    </div>
                    <div className='video col-md-7'>
                        <div className="video-captions">
                            <div>
                                <strong>{this.props.sectionData.sectionContent ? this.props.sectionData.sectionContent.videoTitle : this.props.sectionData.videoTitle}</strong>
                            </div>
                            <div>
                                {this.props.sectionData.sectionContent ? this.props.sectionData.sectionContent.videoExcerpt : this.props.sectionData.videoExcerpt}
                            </div>
                        </div>
                        <Observer  {...options}>
                            <Player autoPlay={true}
                                    ref={(player) => { this.player = player }}
                                    playsInline poster="/assets/poster.png"
                                    src={this.props.sectionData.sectionContent ? this.props.sectionData.sectionContent.videoLink : this.props.sectionData.videoLink}>
                                <ControlBar disableCompletely />
                                <MuteToggle />
                            </Player>
                        </Observer>
                    </div>
                </div>
            </Animated>
        );
    }
};

export default Video;