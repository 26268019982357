import axios from 'axios';
class SiteSettings {
  async getSettings() {
    var settings = await axios.get('https://api.iddigital.com.au/api/v1/siteSettings/')
    
    if(settings.data.body.siteSettings.length > 0) {
      console.log('from service-->>', settings)
      return settings.data.body.siteSettings;
    }
    
  }

}
export default SiteSettings;