import React, {Component} from 'react';

import TextSection from '../textSection/textSectionComponent';
import { Player, ControlBar } from 'video-react';
import MuteToggle from '../video/videoMuteComponent';
import anime from 'animejs/lib/anime.es.js';
import Observer from "@researchgate/react-intersection-observer";

class ImageGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {isVisible: false};
        this.gallery = React.createRef();
        this.cb = this.cb.bind(this);
        this.section = this.props.sectionData.sectionContent ? this.props.sectionData.sectionContent : this.props.sectionData;
        this.handleIntersection = this.handleIntersection.bind(this);
    }

    handleIntersection(event) {
        if (event.isIntersecting) {
            this.player.play();
        } else {
            this.player.pause();
        }
    }

    componentDidMount () {
        let self = this;
        this.animation = anime({
            autoplay: false,
            targets: self.gallery.current.childNodes,
            translateY: [
                { value: [10, 0], duration: 200 }
            ],
            opacity: [
                { value: [0, 1], duration: 1000 }
            ],
            delay: anime.stagger(500, {start: 200}),
            easing: 'easeInOutQuad',
            complete: function() {
                self.setState({isVisible: true});
                self.props.parentCallback();
            }
        });
    }

    componentDidUpdate() {
        if (this.props.isRevealed && !this.state.isVisible) {
            // this.animation.play();
        }
    }

    cb () {
        console.log('reveal component');
        this.animation.play();
        // this.props.parentCallback();
    }

    render() {
        const options = {
            onChange: this.handleIntersection,
            rootMargin: '0% 0% -25%',
        };
        return (
            <div className={"row mb-5 image-gallery " + this.props.styleClass}>
                <div className="col-md-5">
                    <TextSection
                        isRevealed={this.props.isRevealed}
                        parentCallback={this.cb}
                        styleClass="white-text"
                        sectionData={this.props.sectionData} />
                </div>
                <div className='col-md-7' ref={this.gallery}>

                    {this.props.showVideo ?
                        <div className="video">
                            <div className="video-captions">
                                <div>
                                    <strong>{this.section.videoTitle}</strong>
                                </div>
                                <div>
                                    {this.section.videoExcerpt}
                                </div>
                            </div>
                            <Observer  {...options}>
                                <Player autoPlay={true}
                                        ref={(player) => { this.player = player }}
                                        playsInline poster="/assets/poster.png"
                                        muted
                                        src={this.section.videoLink}>
                                    <ControlBar disableCompletely />
                                    <MuteToggle />
                                </Player>
                            </Observer>
                        </div>
                        : null}

                    {this.section.images.length > 0 && this.section.images.map((image, index) => {
                        let side = index % 2 !== 1 ? 'left' : 'right';
                        return (
                            <div className={'image-gallery-' + side}
                                 key={image._id}>
                                {side === 'left' ?
                                    <img src={image.url} />
                                    : null}
                                <div className={'image-gallery-' + side + '-text'}>
                                    <p className="image-gallery-caption-title">{image.title}</p>
                                    <p className="image-gallery-caption-excerpt">{image.excerpt}</p>
                                </div>
                                {side === 'right' ?
                                    <img src={image.url} />
                                    : null}
                            </div>
                            )
                        })
                    }

                </div>
            </div >
        );
    }
}

export default ImageGallery;