import React, {Component} from 'react';
import Splitting from "splitting";
import anime from "animejs";

class ThreeColumnsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {isVisible: false};
        this.titleToSplit = React.createRef();
        this.textToSplit = React.createRef();
        this.textAnime1 = React.createRef();
        this.textAnime2 = React.createRef();
        this.textAnime3 = React.createRef();
        this.title = this.props.sectionData.sectionContent ? this.props.sectionData.sectionContent.title : this.props.sectionData.title;
        this.description = this.props.sectionData.sectionContent ? this.props.sectionData.sectionContent.description : this.props.sectionData.description;
    }

    componentDidMount () {
        let self = this;
        const splitTitle = Splitting({
            target: this.titleToSplit.current
        });
        const splitText = Splitting({
            target: this.textToSplit.current
        });
        const options = {
            autoplay: false,
            translateY: [
                { value: [10, 0], duration: 200 }
            ],
            opacity: [
                { value: [0, 1], duration: 1000 }
            ],
            easing: 'easeInOutQuad',
        };

        this.animation1 = anime({
            ...options,
            targets: [this.title.length ? splitTitle[0].chars : '', this.description.length ? splitText[0].chars : ''],
            delay: anime.stagger(15, {start: 200}), // Please change back to 15 after dev phase
            begin: function () {
                self.setState({isVisible: true});
            },
            complete: function() {
                self.animation2.play();
            }
        });

        this.animation2 = anime({
            ...options,
            targets: [this.textAnime1.current, this.textAnime2.current, this.textAnime3.current],
            delay: anime.stagger(200, {start: 200}),
            complete: function() {
                (self.props.parentCallback && self.props.parentCallback())
            }
        });

        if (this.props.isRevealed && !this.state.isVisible) {
            this.animation1.play();
        }
    }

    componentDidUpdate() {
        if (this.props.isRevealed && !this.state.isVisible) {
            this.animation1.play();
        }
    }

    render () {
        return (
            <div className={"three-columns-section row " + this.props.styleClass}>

                {this.title.length ?
                    <div className="col-12" ref={this.titleToSplit}>
                        <p className="section-title"> {this.title} </p>
                    </div>
                    : ''
                }

                {this.description.length ?
                    <div className="col-12" ref={this.textToSplit}>
                        <div className="section-description">
                            <p dangerouslySetInnerHTML={{__html: this.description}}></p>
                        </div>
                    </div>
                    : ''
                }

                <div className="col-md-4" ref={this.textAnime1}>
                    <div className="section-col"
                         dangerouslySetInnerHTML={{__html: this.props.sectionData.sectionContent ? this.props.sectionData.sectionContent.col1 : this.props.sectionData.column1}}>
                    </div>
                </div>
                <div className="col-md-4" ref={this.textAnime2}>
                    <div className="section-col"
                         dangerouslySetInnerHTML={{__html: this.props.sectionData.sectionContent ? this.props.sectionData.sectionContent.col2 : this.props.sectionData.column2}}>
                    </div>
                </div>
                <div className="col-md-4" ref={this.textAnime3}>
                    <div className="section-col"
                         dangerouslySetInnerHTML={{__html: this.props.sectionData.sectionContent ? this.props.sectionData.sectionContent.col3 : this.props.sectionData.column3}}>
                    </div>
                </div>
            </div>

        )
    }
}

export default ThreeColumnsSection;