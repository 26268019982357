import React, {Component} from "react";
import TextSection from "../../components/sections/textSection/textSectionComponent";
import ImageGallery from "../../components/sections/imageGallery/imageGalleryComponent";
import Video from "../../components/sections/video/videoComponent";
import VideoImage from "../../components/sections/videoImage/videoImageComponent";
import ResponseForm from "../../components/sections/responseForm/responseFormComponent";
import TwoColumnsSection from "../../components/sections/twoColumnsSection/twoColumnsSectionComponent";
import ThreeColumnsSection from "../../components/sections/threeColumnsSection/threeColumnsSectionComponent";
import ButtonsSection from "../../components/sections/buttons/buttons";

class Section extends Component {
    constructor(props) {
        super(props);
        this.state = {
            revealSection : false,
            revealQuestion : false
        };
        this.callbackFunction = this.callbackFunction.bind(this);
    }

    componentDidMount() {
        this.setState({revealSection: true});
        if (this.props.sectionType !== 'text'
                && this.props.sectionType !== 'imageGallery'
                && this.props.sectionType !== 'video' ) {
            this.setState({revealQuestion: true});
        }
    }

    callbackFunction() {
        this.setState({revealQuestion: true});
    }

    render() {
        switch (this.props.sectionType) {
            case 'text' :
                this.sectionElement = <TextSection
                    isRevealed={this.state.revealSection}
                    parentCallback={this.callbackFunction}
                    styleClass="white-text" sectionData={this.props.sectionData}/>;
                break;
            case 'imageGallery' :
                this.sectionElement = <ImageGallery
                    isRevealed={this.state.revealSection}
                    parentCallback={this.callbackFunction}
                    showVideo={false} sectionData={this.props.sectionData}/>;
                break;
            case 'video' :
                this.sectionElement = <Video
                    isRevealed={this.state.revealSection}
                    parentCallback={this.callbackFunction}
                    sectionData={this.props.sectionData}/>;
                break;
            case 'videoImage' :
                this.sectionElement = <VideoImage
                    isRevealed={this.state.revealSection}
                    parentCallback={this.callbackFunction}
                    sectionData={this.props.sectionData}/>;
                break;
            case 'conversionForm' :
                this.sectionElement = <ResponseForm
                    isRevealed={this.state.revealSection}
                    question={this.props.question}
                    parentCallback={this.callbackFunction}
                    triggerMail= {this.props.triggerMail.bind(this)}
                    responseFormDataChange= {this.props.responseFormDataChange.bind(this)}
                    mailDeliveryResponse = {this.props.mailDeliveryResponse}
                    isRevealed={this.state.revealSection}
                    sectionData={this.props.sectionData}/>;
                break;
            case 'twoCols' :
                this.sectionElement = <TwoColumnsSection
                    isRevealed={this.state.revealSection}
                    sectionData={this.props.sectionData}/>;
                break;
            case 'threeCols' :
                this.sectionElement = <ThreeColumnsSection
                    isRevealed={this.state.revealSection}
                    sectionData={this.props.sectionData}/>;
                break;
            default :
                this.sectionElement = <TextSection
                    isRevealed={this.state.revealSection}
                    styleClass="white-text" sectionData={this.props.sectionData}/>;
                break;
        }
        return (
            <div className="row">
                <div className="section-number">
                    <p className="pl-1 "
                       onClick={e => this.props.goToPrev(e)}>
                        <i className="section-number_arrow"></i>
                    </p>
                </div>
                <div className="sections-holder">
                    {this.sectionElement}
                    {this.props.sectionChildren && this.props.sectionType !== 'conversionForm' &&
                    <ButtonsSection
                        isRevealed={this.state.revealQuestion}
                        question={this.props.question}
                        buttons={this.props.buttons}
                        clickHandler={this.props.clickHander.bind(this)} /> }
                </div>
            </div>
        );
    }
}

export default Section;
