// UncontrolledLottie.jsx
import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationJSON from '../lotties/data-2.json'

class ControlledLottie extends Component {

    state = {isStopped: true};

    render(){

        const defaultOptions = {
            loop: true,
            autoplay: false,
            animationData: animationJSON,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return(
            <div className="lottie-wrapper"
                 onMouseOver={() => {this.setState({isStopped: false})}}
                 onMouseLeave={() => this.setState({isStopped: true})}
            >
                <Lottie options={defaultOptions}
                        height={'100%'}
                        isStopped={this.state.isStopped}
                />
            </div>
        )
    }
}

export default ControlledLottie
