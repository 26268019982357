import React, {Component} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TextSection from '../textSection/textSectionComponent';
import ResponseSuccess from './responseSuccessComponent';

class ResponseForm extends Component {
    constructor(props) {
        super(props);
        this.state = {isVisible: false};
        this.cb = this.cb.bind(this);
        this.formCall = React.createRef();
        this.formEmail = React.createRef();
    }

    registerFieldChange(e) {
        // console.log('-->change-->>', e.target.value)
        this.props.responseFormDataChange(e.target.name,e.target.value);
    }

     triggerMail(e, mean) {
        if (mean === 'call') {
            if (!this.formCall.current.checkValidity()) {
                this.formCall.current.reportValidity();
            } else {
                this.props.triggerMail(e);
            }
        } else if (mean === 'email') {
            if (!this.formEmail.current.checkValidity()) {
                this.formEmail.current.reportValidity();
            } else {
                this.props.triggerMail(e);
            }
        }
    }

    cb () {
        // this.animation.play();
        this.setState({isVisible: true});
        this.props.parentCallback();
    }

    render() {

        return (
            <div className={"response-form " + this.props.styleClass}>
                <TextSection
                    isRevealed={this.props.isRevealed}
                    parentCallback={this.cb}
                    styleClass="white-text"
                    sectionData={this.props.sectionData} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="response-form-heading">
                            <p className="section-question">How would you like to get started?</p>
                        </div>
                        {this.props.mailDeliveryResponse && this.props.mailDeliveryResponse.status !== 200 ?
                            <div className="alert">Error occured. Please try again later.</div> :
                            ''
                        }
                        <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" defaultActiveKey="call">
                            <Tab eventKey="call" title="Call">
                                <form action="#" ref={this.formCall}>
                                    <div className="row mt-4">
                                            <div className="col-12">
                                                <p className="response-form-heading">
                                                    {this.props.question}
                                                </p>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <input type="text" className="form-control" name="message" id="validationDefault00"
                                                       placeholder="I want to talk about" required onChange={(e) => this.registerFieldChange(e)}/>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <input type="text" className="form-control" name="name" id="validationDefault01"
                                                       placeholder="My name is" required onChange={(e) => this.registerFieldChange(e)}/>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <input type="tel" className="form-control" name="phone" id="validationDefault02"
                                                       placeholder="My number is" required minLength="8"
                                                       pattern="^\+?\d{8,}"
                                                       onChange={(e) => this.registerFieldChange(e)}/>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <button type="button" className="btn btn-light" onClick={(e) => {
                                                    this.triggerMail(e, 'call');
                                                }}>Send My Details
                                                </button>
                                            </div>
                                    </div>
                                </form>
                            </Tab>
                            <Tab eventKey="email" title="Email">
                                <form action="#" ref={this.formEmail}>
                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <p className="response-form-heading">
                                                {this.props.question}
                                            </p>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <input className="form-control" name="message" id="validationDefault00"
                                                   placeholder="I want to talk about" required onChange={(e) => this.registerFieldChange(e)}/>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <input type="text" className="form-control" name="name" id="validationDefault01"
                                                   placeholder="My name is" required onChange={(e) => this.registerFieldChange(e)}/>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <input type="email" className="form-control" name="email" id="validationDefault02"
                                                   placeholder="My email is" required onChange={(e) => this.registerFieldChange(e)}/>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <button type="button" className="btn btn-light" onClick={(e) => {
                                                this.triggerMail(e, 'email');
                                            }}>Send My Details
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </Tab>
                            {/*<Tab eventKey="liveChat" title="Live Chat">*/}
                            {/*    <div>Live Chat</div>*/}
                            {/*</Tab>*/}
                        </Tabs>
                        {this.props.mailDeliveryResponse && this.props.mailDeliveryResponse.status === 200 ?
                        <ResponseSuccess /> :
                        ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ResponseForm;
