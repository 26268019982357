import React, {Component, useEffect, useRef} from 'react';

import Splitting from "splitting";
import anime from 'animejs/lib/anime.es.js';
import ControlledLottie from "../../UncontrolledLottie";
import ResponseForm from "../responseForm/responseFormComponent";

class ButtonsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
        };
        this.questionToSplit = React.createRef();
        this.buttons = React.createRef();
    }

    componentDidMount() {
        let self = this;
        this.splitQuestion = Splitting({
            target: this.questionToSplit.current
        });
        this.animation1 = anime({
            autoplay: false,
            targets: self.splitQuestion[0].chars,
            translateY:
                [{ value: [10, 0], duration: 200 }],
            opacity:
                [{ value: [0, 1], duration: 1000 }],
            delay: anime.stagger(15),
            easing: 'easeInOutQuad'
        });
        this.animation2 = anime({
            autoplay: false,
            targets: this.buttons.current.children,
            translateY:
                [{ value: [10, 0], duration: 200 }],
            opacity:
                [{ value: [0, 1], duration: 1000 }],
            delay: anime.stagger(150, {start: 500}),
            easing: 'easeInOutQuad',
            complete: function() {
                self.setState({isVisible: true});
            }
        });
    }

    componentDidUpdate() {
        if (this.props.isRevealed && !this.state.isVisible) {
            this.buttons.current.classList.add('button-map--loaded');
            this.animation1.play();
            this.animation2.play();
        }
    }

    render() {
        return (
            <div>
                <div className={"buttons-section row " + this.props.styleClass}>
                    <p className="section-question col-12" ref={this.questionToSplit}>
                        {this.props.question}
                    </p>
                    <div className="button-container">
                        <div className="button-map" ref={this.buttons}>
                            {this.props.buttons.map((sectionBtn) => {
                                return (
                                    <div key={sectionBtn._id}
                                         className={"button d-inline-block " + (sectionBtn.active ? 'button--active' : '' )}
                                         id={sectionBtn._id}
                                         onClick={(e) => {
                                             if (sectionBtn.contentType !== 'link') {
                                                 this.props.clickHandler(e);
                                             } else {
                                                 window.location = sectionBtn.sectionContent;
                                             }
                                         }}>
                                        <div id={sectionBtn._id} className="button-text">
                                            <p>{sectionBtn.buttonText}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ButtonsSection;