import  axios  from 'axios';
class SectionsService {
	async getSections() {
		var section  = await axios.get('https://api.iddigital.com.au/api/v1/journeySections/')
		return section.data.body.sections;
    }

    async getIntroSection() {
        var section = await axios.get('https://api.iddigital.com.au/api/v1/journeySections/getByName/')
        return section.data.body.section;
    }
    async getChildren(id) {
        var children = await axios.get('https://api.iddigital.com.au/api/v1/journeySections/getSectionByParent/' + id)
        return children.data.body.sections
    }
    async getSection(id) {
        var section = await axios.get('https://api.iddigital.com.au/api/v1/journeySections/' + id)
        return section.data.body
    }
}
export default SectionsService;