import React, { Component } from 'react';
import { Router, Route, HashRouter } from 'react-router-dom';
import {history} from 'react-router';
import createHistory from 'history/createBrowserHistory';
import Journey from './views/journeyContainer/journeyContainer.component'
import PagesComponent from './views/pages/pageController.component'

class AppRoute extends Component {
    render() {
        const history = createHistory();
        return (
            <Router history={history}>
                <Route  exact path="/" component={Journey} />
                <Route exact path="/page/:pageName" component={PagesComponent} />
                <Route exact path="/work/:pageName" component={PagesComponent} />
                <Route exact path="/careers/:pageName" component={PagesComponent} />
                <Route exact path="/:pageName" component={PagesComponent} />
            </Router>
        );
    }
}

export default AppRoute;