import React, {Component} from 'react';
import Splitting from "splitting";
import anime from 'animejs/lib/anime.es.js';

class TextSection extends Component {
    constructor(props) {
        super(props);
        this.state = {isVisible: false};
        this.titleToSplit = React.createRef();
        this.textToSplit = React.createRef();
    }

    componentDidMount () {
        let self = this;
        const splitTitle = Splitting({
            target: this.titleToSplit.current
        });
        const splitText = Splitting({
            target: this.textToSplit.current
        });
        const options = {
            autoplay: false,
            translateY: [
                { value: [10, 0], duration: 200 }
            ],
            opacity: [
                { value: [0, 1], duration: 1000 }
            ],
            easing: 'easeInOutQuad',
            delay: anime.stagger(15, {start: 200}), // change back 1 into 15
        };

        this.animation2 = anime({
            ...options,
            targets: splitText[0].chars,
        });
        this.animation1 = anime({
            ...options,
            targets: splitTitle[0].chars,
            begin: function () {
                self.setState({isVisible: true});
            },
            complete: function() {
                self.animation2.play();
                (self.props.parentCallback && self.props.parentCallback());
            }
        });
        if (this.props.isRevealed && !this.state.isVisible) {
            this.animation1.play();
        }
    }

    componentDidUpdate() {
        if (this.props.isRevealed && !this.state.isVisible) {
            this.animation1.play();
        }
    }

    render() {
        return (
            <div className={"row text-section " + this.props.styleClass}>
                <div className="col-12">
                    <p className="section-title" ref={this.titleToSplit}>
                        {this.props.sectionData.sectionContent ? this.props.sectionData.sectionContent.title : this.props.sectionData.title}
                    </p>
                </div>
                <div className="col-12" ref={this.textToSplit}>
                    <div className="section-description">
                        <p dangerouslySetInnerHTML={{__html: this.props.sectionData.sectionContent ? this.props.sectionData.sectionContent.description : this.props.sectionData.description}}></p>
                    </div>
                </div>
            </div>
        );
    }
};

export default TextSection;