import React,{Component} from 'react';
import {Route} from 'react-router';
import Journey from './journeyContainer.component';
// import Transactions from './transactions.component'

class JourneyRouter extends Component {
    render(){
        return (
            <div>
                <Route path="/" component={Journey}/>
            </div>
        );
    }
}

export default JourneyRouter;