import React, {Component} from 'react';

class ResponseSuccess extends Component {
    constructor(props) {
        super(props);
        this.gallery = React.createRef();
    }

    componentDidMount() {
        this.gallery.current.scrollIntoView({behavior: "smooth"});
    }


    render() {
        return (
            <div ref={this.gallery} className="mt-3 mb-5">
                <div className="verticalLine"></div>
                <div className="col-12">
                    <p className="section-title" >
                        Thank you
                    </p>
                </div>
                <div className="col-12">
                    <div className="section-description">
                        <p>
                            Thank you for your details! One of our strategists will get in touch with you shortly to discuss your needs. If you prefer, feel free to call us on 03 9500 9998.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResponseSuccess;



