import React, {Component} from 'react';
import CommonService from '../../services/commonService';
import PageService from '../../services/pagesService';
import TextSection from './../../components/sections/textSection/textSectionComponent';
import TwoColumnsSection from './../../components/sections/twoColumnsSection/twoColumnsSectionComponent';
import ThreeColumnsSection from './../../components/sections/threeColumnsSection/threeColumnsSectionComponent';
import ImageGallery from '../../components/sections/imageGallery/imageGalleryComponent';
import VideoImage from '../../components/sections/videoImage/videoImageComponent'
import ResponseForm from '../../components/sections/responseForm/responseFormComponent';
import Video from '../../components/sections/video/videoComponent';
import {Helmet} from 'react-helmet';
import Loader from "../../components/loader";
import Section from "../journeyContainer/journeySection";

class PageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: null,
            revealSection : false,
            responseFormData: {},
            mailResponse: null,
        };
        this.CommonService = new CommonService();
        this.callbackFunction = this.callbackFunction.bind(this);
        this.PageService = new PageService();
        this.pageName = props.match.params.pageName;
    }

    async componentWillMount() {
        const page = await this.fetchPage();
        console.log('obje-???>>>', page);
        this.setState({
            ...this.state,
            revealSection: true,
            page: page
        });
        console.log('page state->>>', this.state.page)
    }

    async fetchPage() {
        const page = await this.PageService.getPageByName(this.pageName);
        return page;
    }

    callbackFunction() {
        this.setState({revealQuestion: true});
    }

    async triggerMail(e) {
        this.setState({
            ...this.state,
            mailResponse: null
        });
        var mailResponse;
        var mailRep;
        this.setState({
            ...this.state,
            responseFormData: {
                ...this.state.responseFormData,
                journey: this.pageName
            }
        }, mailRep = async () => {
            mailResponse = await this.CommonService.sendMail(this.state.responseFormData);
            console.log(mailResponse);
            this.setState({
                ...this.state,
                responseFormData: {},
                mailResponse: mailResponse
            });
            console.log('mail rep-->', mailResponse);
            return mailResponse;
        });
    }

    responseFormDataChange (name, value) {
        var previousData = this.state.responseFormData;
        console.log('previous-->>', previousData)
        this.setState({
            ...this.state,
            responseFormData: {
                ...previousData,
                [name]: value
            }
        });
        console.log(this.state);
    }


    render() {
        return (
            this.state.page ?
                <div className="page-wrapper">
                    <Helmet>
                        <title>{this.state.page.seoTitle}</title>
                        <meta name="description" content={this.state.page.seoDescription} />
                    </Helmet>
                    <div>
                        <div className="row">
                            <div className="section-number col-1">
                                <p className="pl-1 "
                                   onClick={e => window.history.back(-1)}>
                                    <i className="section-number_prev"></i>
                                </p>
                            </div>
                            <div className="sections-holder">
                                {this.state.page.sections.map((section) => {

                                    switch (section.contentType) {
                                        case 'text' :
                                            this.sectionElement = <TextSection
                                                isRevealed={this.state.revealSection}
                                                parentCallback={this.callbackFunction}
                                                styleClass="white-text" sectionData={section}/>;
                                            break;
                                        case 'imageGallery' :
                                            this.sectionElement = <ImageGallery
                                                isRevealed={this.state.revealSection}
                                                parentCallback={this.callbackFunction}
                                                showVideo={false} sectionData={section}/>;
                                            break;
                                        case 'video' :
                                            this.sectionElement = <Video
                                                isRevealed={this.state.revealSection}
                                                parentCallback={this.callbackFunction}
                                                sectionData={section}/>;
                                            break;
                                        case 'videoImage' :
                                            this.sectionElement = <VideoImage
                                                isRevealed={this.state.revealSection}
                                                sectionData={section}/>;
                                            break;
                                        case 'conversionForm' :
                                            this.sectionElement = <ResponseForm
                                                isRevealed={this.state.revealSection}
                                                parentCallback={this.callbackFunction}
                                                triggerMail= {this.triggerMail.bind(this)}
                                                responseFormDataChange= {this.responseFormDataChange.bind(this)}
                                                mailDeliveryResponse = {this.state.mailResponse}
                                                sectionData={section}/>;
                                            break;
                                        case 'twoCols' :
                                            this.sectionElement = <TwoColumnsSection
                                                isRevealed={this.state.revealSection}
                                                sectionData={section}/>;
                                            break;
                                        case 'threeCols' :
                                            this.sectionElement = <ThreeColumnsSection
                                                isRevealed={this.state.revealSection}
                                                sectionData={section}/>;
                                            break;
                                        default :
                                            this.sectionElement = <TextSection
                                                isRevealed={this.state.revealSection}
                                                styleClass="white-text" sectionData={section}/>;
                                            break;
                                    }
                                    return (
                                        <div class="mb-5" key={section._id}>
                                            {this.sectionElement}
                                            {section.addDivider ?
                                                <hr/>
                                                : null}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            : <Loader />
        )
    }

}

export default PageComponent