import React from 'react';
import icon from './assets/images/icon.svg';
import './App.scss';
import AppRouter from './App.route';
import { Helmet } from 'react-helmet';
import MessengerCustomerChat from 'react-messenger-customer-chat';

// import { Animated } from 'react-animated-css';

function App() {
  return (
    <div className="App">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"></link>
      <script src="https://www.socialintents.com/api/socialintents.1.3.js#2c9fa0e17f85fccb017f8adcdc1e064c" async="async"></script>
      {/*<MessengerCustomerChat*/}
      {/*  pageId="285511978150816"*/}
      {/*  appId="634121537421499"*/}
      {/*/>*/}
      <Helmet titleTemplate="%s" defaultTitle="ID Digital">
        <meta charset="UTF-8" />
        <meta name="description" content="We create brand experiences on digital devices worldwide." />
        {/* <meta name="keywords" content="HTML,CSS,XML,JavaScript" />
        <meta name="author" content="John Doe" /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <header className="App-header">
        <div className="header-container">
        <a href="/" className="header-container--link">
            <img src={icon} className="App-logo" alt="logo" />
            <span className="App-wordmark">ID Digital</span>
        </a>
        </div>
      </header>
      <AppRouter></AppRouter>
    </div>
  );
}

export default App;
